footer {
  padding-top: 70px;
  background-color: rgb(30, 30, 30);
  color: white;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
footer svg {
  width: 27px;
  height: 27px;
  transition: 0.3s;
}
footer svg path {
  stroke: white;
}
.credential {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 1px;
  font-size: 16px;
  padding: 5px 12px;
  width: fit-content;
  transition: 0.3s;
}
.credential:nth-child(1):hover,
.credential:nth-child(2):hover {
  cursor: pointer;
  color: yellowgreen;
  color: orange;
  svg path {
    stroke: yellowgreen;
    stroke: orange;
  }
}
footer > div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
footer > div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}
.after-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgb(22, 22, 22);
  padding: 15px;
}
footer nav {
  display: flex;
  gap: 5px;

  div {
    padding: 12px;
    cursor: pointer;
    border-radius: 5px;
    background-color: rgb(25, 25, 25);
  }
  div:hover {
    color: yellowgreen;
    color: orange;
  }
}
@media screen and (max-width: 1320px) {
  footer nav {
    flex-direction: column;
  }
}
@media screen and (max-width: 920px) {
  footer > div:nth-child(2) {
    flex-direction: column;
  }
}
@media screen and (max-width: 720px) {
  footer > div:nth-child(1) {
    flex-direction: column;
    gap: 15px;
  }
}
