header {
  width: 100%;
  box-shadow: 0 4px 8px rgb(0, 0, 0, 0.1);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10%;
  background-color: rgb(252, 251, 250);
  user-select: none;
  z-index: 20;
  position: relative;
}

header div {
  color: #222;
  cursor: pointer;
}
header > div:first-child {
  display: flex;
  align-items: center;
  gap: 30px;
}
#logo {
  font-size: 24px;
  font-family: "Libre Baskerville", serif;
  font-weight: bold;
}

header nav {
  display: flex;
  align-items: center;
  gap: 30px;
  z-index: 15;
}

header nav div {
  cursor: pointer;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  transition: 0.3s;
}

header nav div::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  width: 0%;
  height: 1.5px;
  background-color: #222;
  transform: translateX(-50%);
  transition: width 0.35s ease-in-out;
}

header nav div:hover::after {
  width: 100%;
}

.overlay {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 14;
}

.menu {
  display: none;
  cursor: pointer;
  fill: #222;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  transition: 0.2s;
}

.show {
  transform: translateX(230px);
}
header .menu:hover {
  fill: orange;
}

@media screen and (max-width: 1000px) {
  .menu {
    display: block;
  }
  header {
    justify-content: start;
    gap: 20px;
    padding: 20px 25px;
  }
  header nav {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    gap: 25px;
    background-color: white;
    box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.4);
    width: 230px;
    left: -230px;
    transition: transform 0.4s ease;
    align-items: start;
    padding-left: 25px;
    overflow-y: auto;
    div {
      font-size: 20px;
    }
  }
  header nav .menu {
    margin: 20px 0;
  }
  header nav div:hover::after {
    width: 0;
  }
  header nav div:hover {
    color: orange;
  }
}
@media screen and (max-width: 1000px) {
  #logo {
    font-size: 1.5rem;
  }
}
