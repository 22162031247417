* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  font-family: Helvetica, Verdana, sans-serif;
  overflow-x: hidden;
}
a {
  text-decoration: none;
  color: inherit;
}

h1 {
  padding: 0;
  text-align: center;
  color: #222;
  margin-bottom: 30px;
  font-family: "TT Norms Pro", sans-serif;
  position: relative;
}

img.fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
